<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      :opacity=".8"
    >
      <div class="d-flex justify-content-end">
        <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          class="mr-1"
          right
          no-flip
          variant="secondary"
          text="Фильтр"
        >
          <b-dropdown-form
            class="py-1"
            @submit.prevent
          >
            <b-form-group
              label="По Ф.И.О."
              label-for="dropdown-form-full_name"
              @submit.prevent.stop
            >
              <b-form-input
                id="dropdown-form-full_name"
                v-model="filters.full_name"
                size="sm"
              />
            </b-form-group>

            <b-form-group
              label="Город:"
              label-for="city_id"
            >
              <v-select
                v-model="filters.city_id"
                label="name"
                :reduce="option => option.id"
                :options="citiesList"
              />
            </b-form-group>

            <b-form-group
              label="Статус"
              label-for="dropdown-form-status"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-status"
                v-model="filters.status"
                size="sm"
                value-field="id"
                text-field="value"
                :options="[{id: null, value: '----------'}, ...ClinicsReviewsConstants.REVIEW_STATUS_LIST]"
              />
            </b-form-group>

            <b-form-group
              label="Кол-во на странице"
              label-for="dropdown-form-per-page"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-per-page"
                v-model="filters.perPage"
                size="sm"
                :options="[10, 20, 50, 100, 500]"
              />
            </b-form-group>

            <b-button-group>
              <b-button
                variant="primary"
                size="sm"
                @click="doApplyFilters"
              >
                Применить
              </b-button>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="doResetFilters"
              >
                Сбросить
              </b-button>
            </b-button-group>
          </b-dropdown-form>
        </b-dropdown>
        <router-link :to="{name: 'clinics-reviews-create'}">
          <b-button variant="primary">
            Создать
          </b-button>
        </router-link>
      </div>

      <div
        class="d-flex"
        :class="selectedRows.length > 0 ? 'justify-content-between' : 'justify-content-end'"
      >
        <div
          v-if="selectedRows.length > 0"
          class="d-flex align-items-center"
        >
          <b-dropdown
            id="dropdown-form1"
            ref="dropdown"
            size="sm"
            right
            variant="warning"
            :text="`Действия c (${selectedRows.length})`"
          >
            <b-dropdown-item
              variant="success"
              @click="actionDropDownCheckbox(selectedRows, '/clinic_reviews/actions/show', 'одобрить')"
            >
              Одобрить
            </b-dropdown-item>
            <b-dropdown-item @click="actionDropDownCheckbox(selectedRows, '/clinic_reviews/actions/hide','отклонить')">
              Отклонить
            </b-dropdown-item>
            <b-dropdown-item
              variant="danger"
              @click="actionDropDownCheckbox(selectedRows, '/clinic_reviews/actions/delete', 'удалить')"
            >
              Удалить
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <data-table
        :columns="columns"
        :rows.sync="rows"
        :settings="settings"
        :dev-logs="false"
        @updateFilter="doUpdateFilter"
      >

        <template
          slot="table-column"
          slot-scope="props"
        >
          <div
            v-if="props.column.label === 'checkbox'"
            class="text-center"
          >
            <b-form-checkbox
              v-model="selectAll"
            />
          </div>
          <div
            v-else
            class="text-center"
          >
            {{ props.column.label }}
          </div>
        </template>

        <template v-slot:table-row="item">
          <div
            v-if="item.column.field === 'checkbox'"
            class="text-center"
          >
            <b-form-checkbox
              v-model="selectedRows"
              :value="item.row.id"
            />
          </div>
          <div
            v-else-if="item.column.field === 'status'"
            class="text-center"
          >
            <span
              v-if="item.row.status === ClinicsReviewsConstants.REVIEW_STATUS_IDS.REVIEW_STATUS_REJECTED"
              class="text-danger"
            >{{ ClinicsReviewsConstants.REVIEW_STATUS_LIST[item.row.status].value }}</span>
            <span
              v-else-if="item.row.status === ClinicsReviewsConstants.REVIEW_STATUS_IDS.REVIEW_STATUS_APPROVED"
              class="text-success"
            >
              {{ ClinicsReviewsConstants.REVIEW_STATUS_LIST[item.row.status].value }}
            </span>
            <span
              v-else-if="item.row.status === ClinicsReviewsConstants.REVIEW_STATUS_IDS.REVIEW_STATUS_MODERATING"
              class="text-warning"
            >
              {{ ClinicsReviewsConstants.REVIEW_STATUS_LIST[item.row.status].value }}
            </span>
            <span v-else>—</span>
          </div>
          <div
            v-else-if="item.column.field === 'action'"
            class="text-center"
          >
            <router-link :to="{name: 'clinics-reviews-update', params: {id: item.row.id}}">
              <b-button
                variant="flat-warning"
                class="btn-icon"
              >
                <feather-icon
                  icon="Edit3Icon"
                  class="text-warning"
                />
              </b-button>
            </router-link>

            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="objectDelete(item.row.id)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="text-danger"
              />
            </b-button>
          </div>
          <div
            v-else
            class="text-center"
          >
            {{ item.formattedRow[item.column.field] }}
          </div>
        </template>
      </data-table>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BDropdown,
  BDropdownForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import DataTable from '@/components/DataTable.vue'
import axiosIns from '@/libs/axios'
import { rowsObjectConverter, settingsObjectConverter } from '@/helpers/dataTableHelper'
import dataTableMixin from '@/mixins/dataTableMixin'
import ClinicsReviewsConstants from '@/consts/clinicsReviews'

export default {
  name: 'ClinicsReviewsList',
  components: {
    BCard,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BButton,
    BButtonGroup,
    BFormSelect,
    BOverlay,
    BFormInput,
    BFormCheckbox,
    DataTable,
    vSelect,
  },
  mixins: [
    dataTableMixin,
  ],
  metaInfo: {
    title: 'Список отзывов для клиники',
  },
  data() {
    return {
      loading: true,
      filterOnURL: false,
      selectedRows: [],
      selectAll: false,
      columns: [
        {
          label: 'checkbox',
          field: 'checkbox',
        },
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Название',
          field: 'clinic.name',
        },
        {
          label: 'Город',
          field: rowObj => (rowObj.clinic.city_id !== null && rowObj.clinic.city_id ? this.getCityName(rowObj.clinic.city_id) : '—'),
        },
        {
          label: 'Отзыв',
          field: 'description',
        },
        {
          label: 'Статус',
          field: 'status',
        },
        {
          label: 'Дата создания',
          field: rowObj => (rowObj.created_at !== null ? this.formateDate(rowObj.created_at) : '—'),
        },
        {
          label: 'Действия',
          field: 'action',
        },
      ],
      citiesList: [],
      ClinicsReviewsConstants,
    }
  },
  watch: {
    selectAll: {
      handler(state) {
        if (state) {
          this.selectedRows = _.map(this.rows, 'id')
        } else {
          this.selectedRows = []
        }
      },
    },
  },
  beforeMount() {
    this.setDefaultFilter({
      status: ClinicsReviewsConstants.REVIEW_STATUS_IDS.REVIEW_STATUS_MODERATING,
      perPage: 50,
    }, () => { this.setFilterToDefault() })

    Promise.all([
      this.doLoadCities(),
    ]).then(() => {
      Promise.all([
        this.doLoadClinicReviews(),
      ]).then(() => {
        this.loading = false
      })
    })

    // Пушим промисы фильтров, чтоб вызывать методы
    this.filterPromises.push(() => this.doLoadClinicReviews())
  },
  methods: {
    async doLoadClinicReviews() {
      const res = await axiosIns({
        method: 'GET',
        url: '/clinic_reviews/list',
        params: _.merge(this.filters),
      })

      this.$set(this, 'settings', settingsObjectConverter(res.data.data))
      this.$set(this, 'rows', rowsObjectConverter(res.data.data))

      return res
    },
    async doLoadCities() {
      const res = await axiosIns({
        method: 'GET',
        url: '/cities/options',
      })

      this.citiesList = res.data.data

      return res
    },
    getCityName(cityId) {
      if (this.citiesList.length === 0) return '—'

      // eslint-disable-next-line radix
      return _.find(this.citiesList, city => city.id === parseInt(cityId)).name ?? '—'
    },
    async objectDelete(id) {
      const modalResult = await this.$swal({
        title: '',
        text: 'Вы уверены что хотите удалить эту запись?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (modalResult.value) {
        await axiosIns({
          method: 'DELETE',
          url: `/clinic_reviews/${id}/delete`,
        })

        await this.doLoadClinicReviews()
        this.$swal({
          icon: 'success',
          title: 'Удалено!',
          text: '',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    },
    async actionDropDownCheckbox(ids, url, message) {
      const modalResult = await this.$swal({
        title: '',
        text: `Вы уверены что хотите ${message}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (modalResult.value) {
        const res = await axiosIns({
          method: 'POST',
          url: `${url}`,
          data: { ids },
        })
        if (res.data.status === 'success') {
          this.loading = true
          await this.doLoadClinicReviews()
          this.loading = false
          this.selectedRows = []
          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Успех!',
            variant: 'success',
          })
        } else {
          this.sendToast({
            title: '',
            icon: 'AlertCircleIcon',
            text: 'Ошибка, попробуй снова!',
            variant: 'danger',
          })
        }
      }
    },
    formateDate(date) {
      if (!date) {
        return '—'
      }

      const datetime = new Date(date.replace(' ', 'T'))
      const dd = String(datetime.getDate()).padStart(2, '0')
      const mm = String(datetime.getMonth() + 1).padStart(2, '0')
      const yyyy = datetime.getFullYear()

      return `${dd}-${mm}-${yyyy}`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
